* {
    margin: 0;
    padding: 0;
}
body {
    font-family: 'Open Sans', sans-serif;
}
canvas {
    display: block;
}
.page {
    /* border: 1px solid #000; */
    margin: 0 auto;
    width: min(90%, 1200px);
}

/* header */
header {
    margin-bottom: 5em;
    position: relative;
}
header img {
    display: block;
    max-width: 100%;
}
h1 {
    color: #047582;
    font-size: 260px;
    font-size: 18vw; 
    left: 50%;
    line-height: 1;
    mix-blend-mode: color-burn;
    padding: 0.5em 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%,-50%);
}
h1,
h2 {
    font-family: 'Playfair Display', serif;
    font-weight: normal;
}

/* grid */
.grid {
    display: grid;
    grid-gap: 2em 0;
    grid-template-columns: 1fr 1fr;
}

.item {
    align-self: center;
    /* border: 1px solid #000; */
    color: #333;
    justify-self: center;
    text-decoration: none;
}
.item:nth-child(odd) {
    justify-self: start;
}
.item:nth-child(even) {
    justify-self: end;
}
.item img {
    display: block;
    opacity: 0;
    width: 100%;
}
.item__image {
    margin-bottom: 0.6em;
    position: relative;
}
.item__meta {
    bottom: 0.4em;
    font-size: 80%;
    left: -0.4em;
    position: absolute;
    text-transform: uppercase;
    transform: rotate(-90deg);
    transform-origin: 0 100%;
}
.item__title {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 0.4em;
}
.item_p {
    line-height: 1.4;
    font-size: 1em;
}
.item_h {
    width: 100%;
}
.item_v {
    width: 80%;
}


/* footer */
.footer {
    border-top: 1px solid #ccc;
    display: flex;
    font-size: 15px;
    line-height: 1.5;
    margin-top: 8em;
    padding: 1em;
    padding: 1em 0 4em 0;
}
.footer a {
    color: #333;
}
.footer p + p {
    margin-left: auto;
    text-align: right;
}

@media screen and (max-width: 600px) { 
    .grid {
        display: block;
    }
    .item_v,
    .item_h {
        margin-bottom: 3em;
        width: auto;
    }
    .item img {
        max-width: none;
        width: 100%;
    }
}

#container {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
}